const cy = {};

cy.trimLeft = function (str, charlist) {
    if (charlist === undefined)
        charlist = "\s";
    return str.replace(new RegExp("^[" + charlist + "]+"), "");
};

cy.trimRight = function (str, charlist) {
    if (charlist === undefined)
        charlist = "\s";
    return str.replace(new RegExp("[" + charlist + "]+$"), "");
};

cy.trim = function(str, charlist) {
    return cy.trimRight(cy.trimLeft(str, charlist), charlist);
};

export default cy;